<template>
  <div>
    <div v-show="viewMode==='grid'">
      <grid-toolbar
        :ref="gridToolbarName"
        :page-name="pageName"
        :grid-ref-name="gridName"
        :selected-rows-data="selectedRowsData"
        :title="title">
        <template slot="toolbarSlotBefore">
          <b-button-group
            v-if="gridConfig!==null && gridConfig.actionPageBtn!==undefined"
            size="sm"
          >
            <div
              v-for="(btn, index) in gridConfig.actionPageBtn"
              :key="index">
              <b-button
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'club'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :title="btn.title"
                variant="primary"
                size="sm"
                style="margin-right: 2px"
                @click="actionPageClicked(btn.command)"
              >
                <feather-icon
                  :icon="btn.icon"
                  size="12"/>
              </b-button>
            </div>
          </b-button-group>
        </template>
        <template
          v-if="gridConfig!==null && gridConfig.actionMultipleRowBtn!==undefined"
          slot="selectedRowsCommandItem">
          <div
            v-for="(btn, index) in gridConfig.actionMultipleRowBtn"
            :key="index">
            <b-dropdown-item
              v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'club'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
              @click="selectedRowsCommand(btn.command)">
              <span class="text-uppercase">{{ btn.label }}</span>
            </b-dropdown-item>
          </div>
        </template>
      </grid-toolbar>

      <dx-data-grid
        :ref="gridName"
        :on-content-ready="gridContentReady"
        :focused-column-index.sync="focusedRowIndex"
        :on-key-down="gridKeyDown"
        :on-focused-row-changing="gridFocusedRowChanging"
        :on-focused-row-changed="gridFocusedRowChanged"
        :on-focused-cell-changed="gridFocusedCellChanged"
        :on-focused-cell-changing="gridFocusedCellChanging"
        :on-selection-changed="selectionChanged"
        :height="gridHeight">
        <div
          slot="actionTpl"
          slot-scope="data">
          <b-dropdown
            :ref="gridName+'RowAction'+data.data.data.id"
            variant="link"
            no-caret
            size="sm">
            <template
              #button-content
              class="ioioii">
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body p-0"
              />
            </template>
            <div
              v-for="(btn, index) in gridConfig.actionRowBtn"
              :key="index">
              <b-dropdown-item
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'club'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                class=""
                @click="actionRowClicked({mode:btn.command, rowData:data.data.data})">
                <feather-icon :icon="btn.icon"/>
                <span class="align-middle ml-50 text-uppercase">{{ btn.label }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <div
          slot="hasChildrenTpl"
          slot-scope="data">
          <b-badge
            v-if="data.data.row.data.aktif"
            variant="success">
            YES
          </b-badge>
          <b-badge
            v-else
            variant="danger">
            NO
          </b-badge>
        </div>

        <dx-column-chooser mode="select"/>
      </dx-data-grid>
    </div>
    <div
      v-if="viewMode==='form'"
      class="mb-3 club-form">
      <form-app
        :ref="formName"
        :form-data-id="formDataId"
        :title="title"
        :form-name="formName"
        :on-form-close="formOnHide"
        :on-form-add-new="formOnAddNew"
        :form-clone-mode="formCloneMode"
        :on-form-field-data-changed="onFormFieldDataChanged"
        :on-form-before-save="formBeforeSave"
        :has-upload-files="true">
        <div
          v-if="additionalFormVisible"
          slot="additionalForm">
          <dx-tab-panel
            ref="tabPanel"
            :data-source="[{title:'Logo'}]"
            :selected-index="0"
            :animation-enabled="false"
            :element-attr="{class:'mt-1 mb-1'}">
            <template #title="{ data: tabs }">
              <span>
                {{ tabs.title }}
                <b-badge
                  v-show="clubImageCount>0"
                  variant="light-primary">
                  {{ clubImageCount }}
                </b-badge>
              </span>
            </template>
            <div
              slot="item"
              slot-scope="dataTab">
              <div
                :id="`containers-${dataTab.data.index}`"
                class="containers">
                <div v-show="clubImage.length === 0">
                  <dx-file-uploader
                    ref="fileUploader"
                    :on-value-changed="filesValueChanged"
                    :multiple="false"
                    :show-file-list="false"
                    select-button-text="Select Image"
                    label-text=""
                    accept="image/*"
                    upload-mode="useForm"
                  />
                </div>

                <dx-tile-view
                  ref="tileView"
                  :items="clubImage"
                  :height="206"
                  :base-item-width="180"
                  :base-item-height="180"
                  :item-margin="10"
                  :active-state-enabled="false"
                  :focus-state-enabled="false"
                  :hover-state-enabled="false"
                  direction="horizontal"
                  no-data-text=""
                >
                  <div
                    slot="item"
                    slot-scope="data">
                    <div
                      :id="`${formName}Foto-${data.data.id}`"
                      class="image tw-border"
                      :style="{ 'background-image': `url(${data.data.filesrc})`, 'height': '180px', 'width': '180px', 'background-position': 'center', 'background-size': 'cover' }" />
                    <b-button
                      variant="danger"
                      size="sm"
                      style="position: absolute; right: 5px; top: 5px"
                      @click.prevent.stop="filesDel">
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-50" />
                    </b-button>
                  </div>
                </dx-tile-view>
              </div>
            </div>
          </dx-tab-panel>
        </div>
      </form-app>
    </div>
    <div
      v-if="viewMode==='reorder'"
      class="mb-3">
      <club-reorder
        :ref="clubReorderName"
        :title="title"
        :form-name="clubReorderName"
        :on-form-close="formOnHide"/>
    </div>
    <audit-grid
      v-if="viewMode==='audit'"
      :ref="auditGridName"
      :title="title"
      :name="auditGridName"
      :hide-event="formOnHide"/>
  </div>
</template>

<script>
import { DxTabPanel } from 'devextreme-vue/tab-panel'
import { DxFileUploader } from 'devextreme-vue/ui/file-uploader'
import { DxTileView } from 'devextreme-vue/ui/tile-view'
import { DefaultPageData } from '@/modules/mixins/data'
import { nextTick } from '@vue/composition-api'
import ClubReorder from '@/views/components/ClubReorder.vue'

const _ = require('lodash')

export default {
  components: {
    ClubReorder,
    DxTabPanel,
    DxFileUploader,
    DxTileView
  },
  props: {
    pageName: {
      type: String,
      default: 'Club'
    },
    title: {
      type: String,
      default: 'Club'
    },
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 198
      }
    }
  },
  data() {
    const data = { ...DefaultPageData }
    this.$stateMerge(data, {
      viewMode: 'grid',
      gridName: `${this.pageName}Grid`,
      gridToolbarName: `${this.pageName}GridToolbar`,
      actionRowButtonName: `${this.pageName}GridActionRowBtn`,
      auditGridName: `${this.pageName}GridAudit`,
      formName: `${this.pageName}Form`,
      formOnHide: `${this.pageName}FormHideEvent`,
      formOnAddNew: `${this.pageName}FormAddNewEvent`,
      delRowsRoute: 'clubDelBatch',
      restoreRowsRoute: 'clubRestoreBatch',
      clubImage: [],
      clubImageRender: [],
      clubReorderName: `${this.pageName}ReorderForm`
    })
    return data
  },
  computed: {
    clubImageCount() {
      return _.chain(this.clubImage).filter(o => o.id_tmp !== null).size().value()
    }
  },
  created() {
    const vm = this
    vm.gridCreated()
  },
  mounted() {
    const vm = this
    vm.gridMount()
    vm.formMount()
    // console.log(vm.$moment('12:30', 'HH:mm'))
  },
  methods: {
    bindMoustrap() {

    },
    actionPageClicked(command) {
      if (command === 'addNew') {
        this.viewMode = 'form'
      } else if (command === 'reorder') {
        this.viewMode = 'reorder'
      }
    },
    actionRowClicked(command) {
      const vm = this
      console.log('actionRowClicked', command)
      setTimeout(() => {
        if (command.mode === 'editRow' || command.mode === 'cloneRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          // eslint-disable-next-line radix
          vm.formDataId = command.rowData.id
          vm.formCloneMode = command.mode === 'cloneRow'
          vm.viewMode = 'form'
        } else if (command.mode === 'deleteRow') {
          vm.delRows([command.rowData.id], false)
        } else if (command.mode === 'restoreRow') {
          vm.restoreRows([command.rowData.id], false)
        } else if (command.mode === 'auditRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          const route = `api/club/${command.rowData.id}/audit`
          vm.viewMode = 'audit'
          nextTick().then(() => {
            vm.$refs[vm.auditGridName].updateRoute(route)
          })
        } else {
          vm.msgShow('Unauthorized access', 'error')
        }
      })
    },
    selectedRowsCommand(command) {
      if (command === 'deleteRow') {
        const vm = this
        setTimeout(() => {
          vm.delRows(this.$_map(this.selectedRowsData, 'id'))
          vm.$refs[vm.gridToolbarName].$refs[`${vm.gridName}SelectedRowsButton`].hide()
        }, 200)
      }
    },
    onFormFieldDataChanged(e) {
      const vm = this.$refs[this.formName]
      if (e.value === undefined || !vm.formItemsSetStatus) {
        return false
      }
    },
    formMount() {
      this.$events.$on(`${this.formName}FormMountedEvent`, () => {
        console.log(`${this.formName}FormMountedEvent`)
        this.additionalFormVisible = true
        this.clubImage = []

        const vm = this.$refs[this.formName]
        const { formDataEdit } = vm
        if (formDataEdit !== undefined && formDataEdit !== null) {
          this.clubImage.push({
            id: 0,
            file: null,
            filename: formDataEdit.logo,
            filesrc: formDataEdit.logo_url
          })
        }
      })
    },
    formBeforeSave() {
      const vm = this
      const { formData } = vm.$refs[vm.formName]

      return new Promise(done => {
        const fd = new FormData()

        _.each(formData, (val, key) => {
          fd.append(key, val)
        })

        const imagesToEdit = []
        _.each(vm.clubImage, val => {
          if (val.file !== null && val.file !== undefined) {
            if (val.file.constructor === File) {
              fd.append('file', val.file)
            }
          } else {
            imagesToEdit.push(val)
          }
        })
        fd.append('images', JSON.stringify(imagesToEdit))

        vm.$refs[vm.formName].formDataHasUploadFiles = fd
        done(true)
      }).catch(error => {
        this.msgShow(error, 'error')
        return false
      })
    },
    filesValueChanged(e) {
      const vm = this
      vm.clubImage = []
      vm.clubImageRender = []
      _.each(e.value, (val, index) => {
        const reader = new FileReader()
        reader.onload = ev => {
          vm.clubImage.push({
            id: index,
            file: val,
            filename: val.name,
            filesrc: ev.target.result
          })
          this.clubImageRender[index] = ev.target.result
        }
        reader.readAsDataURL(val)
      })
      vm.$refs.tabPanel.instance.repaint()
    },
    filesDel() {
      const vm = this
      vm.clubImage = []
    }
  }
}
</script>

<style>
.dx-tile-content {
  height: 100%;
  padding: 0;
  position: relative;
}
.dx-fileuploader-input {
  opacity: 0;
}
.dx-fileuploader-input-wrapper {
  display: flex;
}
.club-form .dx-form-group-content {
  padding-top: 10px !important;
}
</style>
